(function($){

  var scrollPos = 0;
  var carouselPage = 1;
  var additionalColors = $('#more-colors-group img').length;
  var additionalImages = $('#more-images-group img').length;
  var renderingImages = $('#more-renderings-group img').length;

  if(additionalColors < 5) {
    $('#product-page .scroll-right').fadeOut();
  } else if(additionalColors > 5) {
    $('#product-page .scroll-right').fadeIn();
  }

  $('a.additional-images').click(function() {
    $('#more-colors-group').fadeOut();
    $('#more-renderings-group').fadeOut();
    $('#more-images-group').fadeIn();
    $('#more-colors-group').removeClass('active');
    $('#more-renderings-group').removeClass('active');
    $('#more-images-group').addClass('active');
    $('a.additional-colors').removeClass('active');
    $('a.additional-renderings').removeClass('active');
    $('a.additional-images').addClass('active');
    $('.image-container').css('left', '0');
    scrollPos = 0;
    carouselPage = 1;
    additionalImages = $('#more-images-group img').length;
    if(additionalImages < 5) {
      $('#product-page .scroll-right').fadeOut();
    } else if(additionalImages > 5) {
      $('#product-page .scroll-right').fadeIn();
    }
    return false;
  });

  $('a.additional-colors').click(function() {
    $('#more-images-group').fadeOut();
    $('#more-renderings-group').fadeOut();
    $('#more-colors-group').fadeIn();
    $('#more-images-group').removeClass('active');
    $('#more-renderings-group').removeClass('active');
    $('#more-colors-group').addClass('active');
    $('a.additional-images').removeClass('active');
    $('a.additional-renderings').removeClass('active');
    $('a.additional-colors').addClass('active');
    $('.image-container').css('left', '0');
    scrollPos = 0;
    carouselPage = 1;
    additionalImages = $('#more-colors-group img').length;
    if(additionalImages < 5) {
      $('#product-page .scroll-right').fadeOut();
    }
    $('#product-page .scroll-left').fadeOut();
    return false;
  });
  $('a.additional-renderings').click(function() {
    $('#more-images-group').fadeOut();
    $('#more-colors-group').fadeOut();
    $('#more-renderings-group').fadeIn();
    $('#more-colors-group').removeClass('active');
    $('#more-images-group').removeClass('active');
    $('#more-renderings-group').addClass('active');
    $('a.additional-images').removeClass('active');
    $('a.additional-colors').removeClass('active');
    $('a.additional-renderings').addClass('active');
    $('.image-container').css('left', '0');
    scrollPos = 0;
    carouselPage = 1;
    additionalImages = $('#more-renderings-group img').length;
    if(additionalImages < 5) {
      $('#product-page .scroll-right').fadeOut();
    }
    $('#product-page .scroll-left').fadeOut();
    return false;
  });

  $('#product-page .scroll-right').click(function() {
    console.log('workedddd');
    scrollPosMax = $('#container .image-set.active .image-container a img').length;
    whatID = $('#container .image-set.active').attr('id');

    scrollStepMax = scrollPosMax % 5;

    if((scrollPosMax - 5) > 0) {
      if(carouselPage <= scrollStepMax) {
        carouselPage++;
        scrollPos--;

        if(carouselPage > scrollStepMax) {
          $('#product-page .scroll-right').fadeOut();
        }

        if(carouselPage > 1) {
          $('#product-page .scroll-left').fadeIn();
        }

        $('.image-container').animate({
          left: '-=109'
        }, 200, function() {
          //Animation complete
        });
      }
    }
    return false;
  });

  $('#product-page .scroll-left').click(function() {
    scrollPosMax = $('#container .image-set.active .image-container a img').length;
    whatID = $('#container .image-set.active').attr('id');

    if(scrollPos !== 0 ) {
      carouselPage--;
      scrollPos++;
      if(carouselPage === 1) {
        $('#product-page .scroll-left').fadeOut();
      }
      if(carouselPage <= scrollStepMax) {
        $('#product-page .scroll-right').fadeIn();
      }
      $('.image-container').animate({
        left: '+=109'
      }, 200, function() {
        //Animation complete
      });
    }
    return false;
  });
})(jQuery);
