/* jshint ignore:start */

/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        // var waypoint = new Waypoint({
        //   element: document.getElementById('basic-waypoint'),
        //   handler: function() {
        //     console.log('triggered');
        //     notify('Basic waypoint triggered');
        //   }
        // });
        console.log('common');

        function isDate(txtDate) {
          var currVal = txtDate;
          if(currVal == '')
            return false;
           
          //Declare Regex 
          var rxDatePattern = /^(\d{1,2})(\/|-)(\d{1,2})(\/|-)(\d{4})$/;
          var dtArray = currVal.match(rxDatePattern); // is format OK?
         
          if (dtArray == null)
             return false;
          
          //Checks for mm/dd/yyyy format.
          dtMonth = dtArray[1];
          dtDay= dtArray[3];
          dtYear = dtArray[5];
         
          if (dtMonth < 1 || dtMonth > 12)
              return false;
          else if (dtDay < 1 || dtDay> 31)
              return false;
          else if ((dtMonth==4 || dtMonth==6 || dtMonth==9 || dtMonth==11) && dtDay ==31)
              return false;
          else if (dtMonth == 2)
          {
             var isleap = (dtYear % 4 == 0 && (dtYear % 100 != 0 || dtYear % 400 == 0));
             if (dtDay> 29 || (dtDay ==29 && !isleap))
                  return false;
          }
          return true;
        }
        $('.dateValidate').focusout(function(e) {
            txtDate = $(this).val();

            if (isDate(txtDate) === false) {
              console.log('was false');
              $(this).parent().addClass('has-error');
            } else {
              $(this).parent().removeClass('has-error');
              console.log('was true');
            }

            // isDate(txtDate);
            // if (false) {
            //   console.log('was false');
            // } else if(true){
            //   console.log('was true');
            // }

        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'shiirnow': {
      init: function() {
        // var $grid = $('body .shiirnow-grid').isotope({
        //   itemSelector: 'body .col-sm-4',
        //   masonry: {
        //     columnWidth: '.col-sm-4',
        //   }
        // });
        // $grid.imagesLoaded().progress( function() {
        //   $grid.isotope('layout');
        // });
        // $("img.img-responsive").lazyload({
        //     effect : "fadeIn",
        //     load: function(){
        //       $grid.isotope('layout');
        //     }
        // });

        $(document).on('facetwp-loaded', function() {
          // if (FWP.loaded) { // trigger only after the initial refresh
            // setTimeout(function() {
              $blogGrid = $( '.shiirnow-grid' ); // this to the class or id to apply isotope to
              $blogGrid.isotope('destroy');
              $blogGrid.isotope( {
                  // options - change these options to match the isotope options in use - https://isotope.metafizzy.co/options.html
                  itemSelector: '.grid-item',
                  layoutMode: 'packery',
                  // masonry: {
                  //   columnWidth: '.grid-sizer',
                  // }

              });
              $blogGrid.imagesLoaded(function() {
                  $blogGrid.isotope('layout');
                });
              
            // }, 3000);
                // setTimeout(function(){
                //   $blogGrid.imagesLoaded(function() {
                //     $blogGrid.isotope('layout');
                //   });
                // }, 500);
            // }
          });
          
          $(document).on("click", ".facetwp-per-page-select span", (function () {
            // FWP.extras.per_page = $(this).val(),
            $('.facetwp-per-page-select .active').removeClass('.active');
            $(this).addClass('active');
            FWP.extras.per_page = $(this)[0].innerText,
              FWP.soft_refresh = !0,
              FWP.autoload()

          }));

          $(document).on('click', '.facetwp-selections .facetwp-selection-value', function() {
            if (FWP.is_refresh) {
                return;
            }

            var facet_name = $(this).attr('data-facet');
            var facet_value = $(this).attr('data-value');

            if ('' != facet_value) {
                var obj = {};
                obj[facet_name] = facet_value;
                FWP.reset(obj);
            }
            else {
                FWP.reset(facet_name);
            }
        });


          FWP.hooks.addAction('facetwp/loaded', function () {
        
            var selections = '';
            jQuery.each(FWP.facets, function (key, val) {
                if (val.length < 1 || 'undefined' === typeof FWP.settings.labels[key]) {
                    return true; // skip this facet
                }
                var choices = val;
                var facet_type = jQuery('.facetwp-facet-' + key).attr('data-type');
                choices = FWP.hooks.applyFilters('facetwp/selections/' + facet_type, choices, {
                    'el': jQuery('.facetwp-facet-' + key),
                    'selected_values': choices
                });
        
                if ('string' === typeof choices) {
                    choices = [{ value: '', label: choices }];
                }
                else if ('undefined' === typeof choices[0].label) {
                    choices = [{ value: '', label: choices[0] }];
                }
                var values = '';
                jQuery.each(choices, function (indx, choice) {
                   //ensure every selected facet item is wrapped around a data-facet-[key] li element to leave other functionality intact
                    selections += '<li class="facetwp-selection-value" data-facet="' + key + '" data-value="' + choice.value + '">' + FWP.helper.escape_html(choice.label) + '</li>';
                });
        
            });
        
            if ('' !== selections) {
                selections = '<ul><h4 class="applied-filters">Applied Filters</h4> <li class="facetwp-selection-value" onclick="FWP.reset()">Clear All</li>' + selections + '</ul>';
            }
            //console.log(selections);
            jQuery('.facetwp-selections').html(selections);

            // if (isset(response.per_page)) {
            //   $('.facetwp-per-page').html(response.per_page);
            //   if ('default' !== FWP.extras.per_page) {
            //       $('.facetwp-per-page-select').val(FWP.extras.per_page);
            //   }
            // }
  
        
        }, 12);


      },
      finalize: function() {
        // var $grid = $('body .shiirnow-grid').isotope({
        //   itemSelector: 'body .col-sm-4',
        //   masonry: {
        //     columnWidth: '.col-sm-4',
        //   }
        // });
        // $grid.imagesLoaded().progress( function() {
        //   $grid.isotope('layout');
        // });


        // $(document).on('facetwp-loaded', function() {
        //   if (FWP.loaded) { // trigger only after the initial refresh
        //         console.log('thing');
        //         $blogGrid = $( 'body .shiirnow-grid' ); // this to the class or id to apply isotope to
        //         $blogGrid.isotope( {
        //             // options - change these options to match the isotope options in use - https://isotope.metafizzy.co/options.html
        //             itemSelector: 'body .col-sm-4',
        //             layoutMode: 'masonry'
        //         });
        //         $blogGrid.imagesLoaded(function() {
        //             $blogGrid.isotope('layout');
        //         });
        //         // setTimeout(function(){
        //         //   $blogGrid.imagesLoaded(function() {
        //         //     $blogGrid.isotope('layout');
        //         //   });
        //         // }, 500);
        //     }
        //  });

        }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'page_template_template_masonry': {
      init: function() {

        var $grid = $('.img-grid').isotope({
          itemSelector: '.single-img',
          masonry: {
            columnWidth: '.single-img',
          }
        });
        $grid.imagesLoaded().progress( function() {
          $grid.isotope('layout');
        });
        $("img.lazy").lazyload({
            effect : "fadeIn",
            load: function(){
              $grid.isotope('layout');
            }
        });
      }
    },
    'single_sh_portfolios': {
      init: function() {

        var $grid = $('.img-grid').isotope({
          itemSelector: '.single-img',
          masonry: {
            columnWidth: '.single-img',
          }
        });
        $grid.imagesLoaded().progress( function() {
          $grid.isotope('layout');
        });
        $("img.lazy").lazyload({
            effect : "fadeIn",
            load: function(){
              $grid.isotope('layout');
            }
        });
        $(".video-container").fitVids();

        var $videos = $('.video-container iframe');

        $(".video-close").click(function() {
            $videos.each(function(i, video) {
              var vidsrc = $(video).attr('src');
              $(video).attr('src','');
              $(video).attr('src', vidsrc);
            });
        });

        $('body').click(function(){
            $videos.each(function(i, video) {
              var vidsrc = $(video).attr('src');
              $(video).attr('src','');
              $(video).attr('src', vidsrc);
            });

        });


      }
    },
    'single_sh_patterns': {
      init: function(){
        $('#draggable').draggable();
        $('#product-page .image-set .image-container a.additional-image-thumb > img').click(function() {
          var imageClicked = $(this).attr('rel');
          var whatFolder = $(this).attr('src');
          var fullImg = $(this).attr('data-full');
          if(whatFolder.toLowerCase().indexOf('/additional/') >= 0) {
            whatFolder = 'additional';
          } else {
            whatFolder = 'colors';
          }
          $('.img-wrap > figure').html('<img class="zoom-image" src="'+fullImg+'" >');
          return false;
        });
        // Carousel load image
        $('#modal .image-set .image-container a.additional-image-thumb > img').click(function(){
          var imageClicked = $(this).attr('rel');
          var whatFolder = $(this).attr('src');
          var fullImg = $(this).attr('data-full');
          if(whatFolder.toLowerCase().indexOf('/additional/') >= 0) {
            whatFolder = 'additional';
          } else {
            whatFolder = 'colors';
          }
          $('#modal .zoom-frame #draggable').html('<img class="zoom-image" src="'+fullImg+'" width="100%" height="100%" >');

          stepPos = 0;

          if(stepPos === 0) {
            $('.zoom-minus').css('opacity', '0.60');
          }
          if(stepPos !== 0) {
            $('.zoom-minus').css('opacity', '1.00');
          }
          if(stepPos < stepMax) {
            $('.zoom-plus').css('opacity', '1.00');
          }
          if(stepPos === 0) {
            $('.zoom-minus').css('opacity', '0.60');
          }

          return false;
        });

        $('.color-link').click(function(){
              var colorClicked = $(this).html();
              var imgLink = $(this).data('img');
              // for(i=0; i<colorsList.length; i++) {
              //   colorFile = colorsList[i];
              //   findIt = colorFile.indexOf(colorClicked, 0);
              //   if(findIt !== -1) {
              //     foundIt = colorFile;
              //   }
              // }

          $('.main-image .img-wrap > figure').html('<img class="zoom-image" src="'+imgLink+'" >');
          return false;
        });


      }
    },
    'single_shn_rugs': {
      init: function(){
        $('#draggable').draggable();
        $('#product-page .image-set .image-container a.additional-image-thumb > img').click(function() {
          var imageClicked = $(this).attr('rel');
          var dataTarget = $(this).data('id-target');
          $('.img-wrap .img-responsive').removeClass('active');
          console.log($('.img-wrap .img-responsive[data-img-id='+dataTarget+']'));
          $('.img-wrap .img-responsive[data-img-id='+dataTarget+']').addClass('active');
          var fullImg = $(this).attr('data-full');

        });
        // Carousel load image
        $('#modal .image-set .image-container a.additional-image-thumb > img').click(function(){
          var imageClicked = $(this).attr('rel');
          var whatFolder = $(this).attr('src');
          var fullImg = $(this).attr('data-full');
          if(whatFolder.toLowerCase().indexOf('/additional/') >= 0) {
            whatFolder = 'additional';
          } else {
            whatFolder = 'colors';
          }
          $('#modal .zoom-frame #draggable').html('<img class="zoom-image" src="'+fullImg+'" width="100%" height="100%" >');

          stepPos = 0;

          if(stepPos === 0) {
            $('.zoom-minus').css('opacity', '0.60');
          }
          if(stepPos !== 0) {
            $('.zoom-minus').css('opacity', '1.00');
          }
          if(stepPos < stepMax) {
            $('.zoom-plus').css('opacity', '1.00');
          }
          if(stepPos === 0) {
            $('.zoom-minus').css('opacity', '0.60');
          }

          return false;
        });
      }
    },
    'quotes': {
      init: function() {

        $('.toggle-edit').click(function(e) {
          e.preventDefault();
          $('.frontier-post-list-text').toggle();
        });

        function commaSeparateNumber(val){
            while (/(\d+)(\d{3})/.test(val.toString())){
              val = val.toString().replace(/(\d+)(\d{3})/, '$1'+','+'$2');
            }
            return val;
          }

        var pc, pn, pw, po, maxlength, leadtime;
        count = 0;

        var d = new Date();

        var month = d.getMonth()+1;
        var day = d.getDate();

        var fullDate = ((''+month).length<2 ? '0' : '') + month + '/' +
            ((''+day).length<2 ? '0' : '') + day + '/' +
            d.getFullYear();
        if(!$('#_sh_quote_date').val()) {
          $('#_sh_quote_date').val(fullDate);
        }

        function internalQuote(){
          //calculate cost
          size_sub_cost = parseFloat(pc * sq_ft).toFixed(2);
          if(w_total > 12){
            oversize = true;
            over_sub_cost = parseFloat(size_sub_cost * 0.15).toFixed(2);
            $('#_sh_quote_over_sub_cost').val(over_sub_cost);
          } else {
            oversize = false;
            over_sub_cost = 0;
            $('#_sh_quote_over_sub_cost').val(null);
          }
          $('#_sh_quote_size_sub_cost').val(size_sub_cost);

          size_sub_wholesale = parseFloat(pw * sq_ft).toFixed(2);
          if(w_total > 12){
            oversize = true;
            over_sub_wholesale = parseFloat(size_sub_wholesale * 0.15).toFixed(2);
            $('#_sh_quote_over_sub_wholesale').val(over_sub_wholesale);
          } else {
            oversize = false;
            over_sub_wholesale = 0;
            $('#_sh_quote_over_sub_wholesale').val(null);
          }
          $('#_sh_quote_size_sub_wholesale').val(size_sub_wholesale);
          //calculate wholesale
          //calculate percentage discounts
          perc_discount = ($('#_sh_quote_discount_percent').val()) / 100;
          perc_discount_test = (perc_discount === 0 ? NaN : perc_discount);
          perc_disc_net = parseFloat(size_sub * perc_discount_test).toFixed(2);
          perc_disc_net_p = (isNaN(perc_disc_net) ? null : perc_disc_net);
          perc_disc_net_p_disp = ((isNaN(perc_disc_net * (-1))) ? null : (perc_disc_net * (-1)).toFixed(2));
          console.log('perdis'+perc_disc_net_p_disp);
          $('#_sh_quote_discount_percent_display_net').val(perc_disc_net_p_disp);


          perc_disc_wholesale = parseFloat(size_sub_wholesale * perc_discount_test).toFixed(2);
          perc_disc_wholesale_p = (isNaN(perc_disc_wholesale) ? null : perc_disc_wholesale);
          // perc_disc_wholesale_int = (isNaN(perc_disc_wholesale_int = parseFloat(perc_disc_wholesale)) ? 0 : perc_disc_wholesale_int);
          perc_disc_wholesale_p_disp = ((isNaN(perc_disc_wholesale * (-1))) ? null : (perc_disc_wholesale * (-1)).toFixed(2));
          $('#_sh_quote_discount_percent_display_wholesale').val(perc_disc_wholesale_p_disp);

          perc_disc_cost = parseFloat(size_sub_cost * perc_discount_test).toFixed(2);
          perc_disc_cost_p = (isNaN(perc_disc_cost) ? null : perc_disc_cost);
          // perc_disc_cost_int = (isNaN(perc_disc_cost_int = parseFloat(perc_disc_cost)) ? 0 : perc_disc_cost_int);
          perc_disc_cost_p_disp = ((isNaN(perc_disc_cost * (-1))) ? null : (perc_disc_cost * (-1)).toFixed(2));
          $('#_sh_quote_discount_percent_display_cost').val(perc_disc_cost_p_disp);

          //calculate $ amount discount
          discount_amount = parseFloat($('#_sh_quote_discount_amount').val()).toFixed(2);
          discount_amount_p = (isNaN(discount_amount) ? null  : parseFloat(discount_amount).toFixed(2));
          discount_amount_decimal = parseFloat(discount_amount_p).toFixed(2);
          discount_amount_p_disp = (isNaN(discount_amount) ? null  : parseFloat(discount_amount *(-1)).toFixed(2));
          $('.discount_amount_display').val(discount_amount_p_disp);

          //calculate miscellaneous expense

          misc_amount = parseFloat($('#_sh_quote_misc_desc_sub').val());
          // misc_amount_n = Number($('#_sh_quote_misc_desc_sub').val());
          misc_amount_p = (isNaN(misc_amount) ? null  : parseFloat(misc_amount).toFixed(2));
          misc_amount_decimal = parseFloat(misc_amount_p);
          $('.sh_quote_misc_desc_sub_display').val(misc_amount_p);

          //calculate total amount with discounts
          total_amount = parseFloat(cut_sub_int + over_sub_int + size_sub_int + seal_sub_int + ship_sub_int + strike_sub_int - perc_disc_net_p - discount_amount_p + Number(misc_amount_p)).toFixed(2);
          deposit_amount = parseFloat(total_amount / 2).toFixed(2) ;
          $('#_sh_quote_deposit').val(commaSeparateNumber(deposit_amount));
          $('#_sh_quote_total_net').val(commaSeparateNumber(total_amount));



          //calculate total wholesale
          size_sub_wholesale_int = (isNaN(size_sub_wholesale_int = parseFloat(size_sub_wholesale)) ? 0 : size_sub_wholesale_int);
          over_sub_wholesale_int = (isNaN(over_sub_wholesale_int = parseFloat(over_sub_wholesale)) ? 0 : over_sub_wholesale_int);
          total_amount_wholesale = parseFloat(cut_sub_int + over_sub_wholesale_int + size_sub_wholesale_int + seal_sub_int + ship_sub_int + strike_sub_int - perc_disc_wholesale_p - discount_amount_p + Number(misc_amount_p)).toFixed(2);
          $('#_sh_quote_total_wholesale').val(commaSeparateNumber(total_amount_wholesale));

          //calculate total cost
          size_sub_cost_int = (isNaN(size_sub_cost_int = parseFloat(size_sub_cost)) ? 0 : size_sub_cost_int);
          over_sub_cost_int = (isNaN(over_sub_cost_int = parseFloat(over_sub_cost)) ? 0 : over_sub_cost_int);
          total_amount_cost = parseFloat(cut_sub_int + over_sub_cost_int + size_sub_cost_int + seal_sub_int + ship_sub_int + strike_sub_int - perc_disc_cost_p - discount_amount_p + Number(misc_amount_p)).toFixed(2);
          $('#_sh_quote_total_cost').val(commaSeparateNumber(total_amount_cost));

          //uk totals
          exchange_rate = $('#_sh_quote_uk_exchange_rate').val();
          uk_total_amount = parseFloat((cut_sub_int + over_sub_int + size_sub_int + seal_sub_int + ship_sub_int + strike_sub_int - perc_disc_net_p - discount_amount_p + Number(misc_amount_p)) * Number(exchange_rate)).toFixed(2);
          uk_total_amount_wholesale = parseFloat((cut_sub_int + over_sub_wholesale_int + size_sub_wholesale_int + seal_sub_int + ship_sub_int + strike_sub_int - perc_disc_wholesale_p - discount_amount_p + Number(misc_amount_p)) * exchange_rate).toFixed(2);
          uk_total_amount_cost = parseFloat((cut_sub_int + over_sub_cost_int + size_sub_cost_int + seal_sub_int + ship_sub_int + strike_sub_int - perc_disc_cost_p - discount_amount_p + Number(misc_amount_p)) * exchange_rate).toFixed(2);
          uk_deposit_amount = parseFloat(uk_total_amount / 2).toFixed(2) ;
          $('#_sh_quote_uk_deposit').val(commaSeparateNumber(uk_deposit_amount));
          $('#_sh_quote_uk_total_net').val(commaSeparateNumber(uk_total_amount));
          $('#_sh_quote_uk_total_wholesale').val(commaSeparateNumber(uk_total_amount_wholesale));
          $('#_sh_quote_uk_total_cost').val(commaSeparateNumber(uk_total_amount_cost));

        }

        function quoteGen(input_element){

          if($(input_element).hasClass('input-inches')){
            if(parseInt(input_element.val()) > 11){
              input_element.popover('show');
              input_element.val(11);
            } else {
              input_element.popover('hide');
            }
          }
          if($(input_element).hasClass('input-feet-width')){
            if(parseInt(input_element.val()) > 20){
              if( quoteajax.curuse !== 1 || quoteajax.curuse !== 36 ) return;
              alert('Please contact SHIIR for lengths greater than 20 feet.');
              input_element.popover('show');
              input_element.val(11);
            } else {
              input_element.popover('hide');
            }
          }
          metricInput = false;
          if ($(input_element).hasClass('metric')) {

            metricInput = true;

            if ($(input_element).is('#width-meters')) {
              w_convert = $(input_element).val();

              inches_per_meter = 39.3700787;
              inches_total = w_convert * inches_per_meter ; /* round to integer */
              wfeet = Math.floor((inches_total / 12)) ; /* assumes division truncates result; if not use floor() */
              winches = Math.round(inches_total % 12) ;/* modulus */
              if( $('.frontier_post_form').hasClass('showroom') && wfeet > 20) {
                if( quoteajax.curuse !== 1 || quoteajax.curuse !== 36 ) return;
                alert('Please contact SHIIR for widths greater than 20 feet.');
                input_element.val(0);
                return;
              }

              $('#width-feet').val(wfeet);
              $('#width-inches').val(winches);

            }
            if ($(input_element).is('#length-meters')) {
              l_convert = $(input_element).val();

              inches_per_meter = 39.3700787;
              inches_total = l_convert * inches_per_meter ; /* round to integer */
              lfeet = Math.floor((inches_total / 12)) ; /* assumes division truncates result; if not use floor() */
              linches = Math.round(inches_total % 12) ;/* modulus */

              if( $('.frontier_post_form').hasClass('showroom') && lfeet > 20) {
                if( quoteajax.curuse !== 1 || quoteajax.curuse !== 36 ) return;
                alert('Please contact SHIIR for widths greater than 20 feet.');
                input_element.val(0);
                return;
              }

              $('#length-feet').val(lfeet);
              $('#length-inches').val(linches);

            }
          }
          if ($('#frontier_post').hasClass('custom-selected')){
            pc = $('#_sh_quote_size_sub_cost_display').val();
            pn = $('#_sh_quote_size_sub_net_display').val();
            pw = $('#_sh_quote_size_sub_wholesale_display').val();
            po = $('#_sh_quote_sel_origin').val();
            maxlengthFt = (isNaN( maxlengthFt = parseInt($('#_sh_quote_custom_length_ft').val(), 10) ) ? 0 : maxlengthFt);
            maxlengthIn = (isNaN( maxlengthIn = parseInt($('#_sh_quote_custom_length_in').val(), 10) ) ? 0 : maxlengthIn);
            maxlength = (maxlengthFt * 12) + maxlengthIn;
            console.log('ml:' +maxlength);
            leadtime = $('#_sh_quote_custom_lead').val();
            handtufted = "no";

          }
          count++;
          // console.log('pn:'+pn+'pw:'+pw+'pc:'+pc);

          //calculate width
          w_ft_int = (isNaN(w_ft_int = parseInt($("#width-feet").val(), 10) * 12) ? 0 : w_ft_int);
          w_in_int = (isNaN(w_in_int = parseInt($("#width-inches").val(), 10)) ? 0 : w_in_int);

          w_total = (w_ft_int + w_in_int)/12;
          w_total_inch = (w_ft_int + w_in_int);
          if( $('.frontier_post_form').hasClass('showroom') && w_total_inch > 240) {
              alert('Please contact SHIIR for widths greater than 20 feet.');
              input_element.val(0);
              return;
          }

          // w_meters = parseFloat(inches_per_meter / w_total_inch);
          // $('#width-meters').val(w_meters);
          console.log('w_total: '+w_total);
          //calculate length
          l_ft_int = (isNaN(l_ft_int = parseInt($("#length-feet").val(), 10) * 12) ? 0 : l_ft_int);
          l_in_int = (isNaN(l_in_int = parseInt($("#length-inches").val(), 10)) ? 0 : l_in_int);

          l_total = (l_ft_int + l_in_int)/12;
          l_total_inch = (l_ft_int + l_in_int);
          if( $('.frontier_post_form').hasClass('showroom') && l_total_inch > 360) {
              alert('Please contact SHIIR for lengths greater than 30 feet.');
              input_element.val(0);
              return;
          }
          // l_meters = parseFloat(inches_per_meter / l_total_inch);
          // $('#length-meters').val(l_meters);


          if($(input_element).hasClass('imperial')) {

            //converting to metric, for UK forms
            if (typeof metricInput === 'undefined' || metricInput === false){
              meters_per_inch = 0.0254;
              w_metric = meters_per_inch * w_total_inch;
              l_metric = meters_per_inch * l_total_inch;
              $('#width-meters').val(w_metric.toFixed(2));
              $('#length-meters').val(l_metric.toFixed(2));

            }

          }

          console.log('l_total:'+l_total);
          //calculate square footage
          sq_ft = Math.ceil(w_total * l_total);
          roundSF = Math.ceil(sq_ft);
          console.log('sf:'+roundSF);
          $('#_sh_quote_size_sf').val(roundSF);
          //calculate net cost
          size_sub = parseFloat(pn * sq_ft).toFixed(2);
          $('#_sh_quote_size_sub_net').val(size_sub);
          // calculate the wholesale Rug cost for valt-users
          if($('.valt-user').length > 0) {
            var  wholesale_cost = parseFloat(pw * sq_ft).toFixed(2);
            $('#_sh_quote_size_sub_wholesale').val(wholesale_cost);
          }

          //checking our radios, and switching the dropdowns as necessary

          // if(input_change !== undefined){
          //   if(input_change.data('target')) {
          //     console.log(input_change.closest('.col-sm-4').find(input_change.data('target')));
          //     input_change.closest('.col-sm-4').find(input_change.data('target')).val(input_change.val());
          //   } else {

          //   }
          // }

          //shipping and handling

          if ($('#_sh_quote_ship').val() === 'Yes'){
            shipRate = $('#_sh_quote_sel_pattern').find(':selected').attr('data-pat-ship');
            ship_sub = parseFloat(shipRate * sq_ft).toFixed(2);
            $('#_sh_quote_ship_sub, .sh_quote_ship_sub_display').val(ship_sub);
          } else {
            ship_sub = 0;
            $('#_sh_quote_ship_sub, .sh_quote_ship_sub_display').val(null);
          }


          //check if width is over 12', but only if form has default-width-fee class
          if(w_total > 12 && $('.valt-user').length === 0 ){
            oversize = true;
            over_sub = parseFloat(size_sub * 0.15).toFixed(2);
            $('#_sh_quote_over_sub, .sh_quote_over_sub_dup').val(over_sub);
          } else {
            oversize = false;
            over_sub = 0;
            $('#_sh_quote_over_sub, .sh_quote_over_sub_dup').val(null);
          }
          //check for rug pad cut
          if ($('#_sh_quote_pad').val() === 'Yes'){
            cut_sub = parseFloat(2.25 * sq_ft).toFixed(2);
            $('#_sh_quote_cut_sub, .sh_quote_cut_sub_display').val(cut_sub);
          } else {
            cut_sub = 0;
            $('#_sh_quote_cut_sub, .sh_quote_cut_sub_display').val(null);
          }

          //check for fiber seal
          if ($('#_sh_quote_seal').val() === 'Yes'){
            seal_sub = parseFloat(2.25 * sq_ft).toFixed(2);
            $('#_sh_quote_seal_sub, .sh_quote_seal_sub_display').val(seal_sub);
          } else {
            seal_sub = 0;
            $('#_sh_quote_seal_sub, .sh_quote_seal_sub_display').val(null);
          }


          //check for strike-offs
          strike_num = parseInt($('#_sh_quote_strike').val());
          if (strike_num === 0) {
            strike_sub = null;
          } else {
            strike_sub = parseFloat(175 * strike_num).toFixed(2);
          }
          // strike_num_test = (strike_num === 0 ? NaN : strike_num);

          $('#_sh_quote_strike_sub, .sh_quote_strike_sub_display').val(strike_sub);
          //setup strike-off lead time

          if ( $('.frontier_post_form').hasClass('showroom')){
            if(strike_num > 0){
              switch (po){
                case 'India':
                  $('#_sh_quote_strike_lead').val("6-8 Weeks");
                  break;
                case 'Nepal':
                  $('#_sh_quote_strike_lead').val("4-6 Weeks");
                  break;
                case 'Thailand':
                  $('#_sh_quote_strike_lead').val("4-6 Weeks");
                  break;
                case 'Uruguay':
                  $('#_sh_quote_strike_lead').val("4-6 Weeks");
                  break;
                case 'Italy':
                  $('#_sh_quote_strike_lead').val("4-6 Weeks");
                  break;
              }
            } else {
                $('#_sh_quote_strike_lead').val('');
            }
          } else {
            if(strike_num <= 0){
                $('#_sh_quote_strike_lead').prop('selectedIndex', 0);
            }
          }

          //calculate lead time
          //added leadtime override for handtufted rugs
          if(handtufted === "yes"){
            lead_sub = "8-12 weeks + shipping";
            $('#_sh_quote_calc_lead').val(lead_sub);

          } else {
            lead_sub = Math.ceil((l_total_inch / maxlength)*2)/2 + parseFloat(leadtime);
            if(isNaN(lead_sub)) {
              if($('#_sh_quote_sel_pattern').val() === ''){
                $('#_sh_quote_calc_lead').val('');
              } else {
                $('#_sh_quote_calc_lead').val('Enter L/Mo. + Lead Above');
              }
            } else {
              $('#_sh_quote_calc_lead').val(lead_sub+' Months');
            }
            $('#_sh_quote_calc_lead').val(lead_sub+ ' Months');
          }


          //calculate total
          cut_sub_int = (isNaN(cut_sub_int = parseFloat(cut_sub)) ? 0 : cut_sub_int);
          over_sub_int = (isNaN(over_sub_int = parseFloat(over_sub)) ? 0 : over_sub_int);
          size_sub_int = (isNaN(size_sub_int = parseFloat(size_sub)) ? 0 : size_sub_int);
          seal_sub_int = (isNaN(seal_sub_int = parseFloat(seal_sub)) ? 0 : seal_sub_int);
          ship_sub_int = (isNaN(ship_sub_int = parseFloat(ship_sub)) ? 0 : ship_sub_int);
          strike_sub_int = (isNaN(strike_sub_int = parseFloat(strike_sub)) ? 0 : strike_sub_int);

          total_amount = parseFloat(cut_sub_int + over_sub_int + size_sub_int + seal_sub_int + ship_sub_int + strike_sub_int).toFixed(2);

          //calculate deposit - valt users base off wholesale total

          if($('.valt-user').length > 0) {
            wholesale_cost_int = parseFloat(wholesale_cost);
            total_amount_wholesale = parseFloat( wholesale_cost_int + cut_sub_int + over_sub_int + seal_sub_int + ship_sub_int + strike_sub_int).toFixed(2);
            deposit_amount_wholesale = (parseFloat(total_amount_wholesale) / 2).toFixed(2) ;
            $('#_sh_quote_total_wholesale').val(commaSeparateNumber(total_amount_wholesale));
            $('#_sh_quote_deposit_wholesale').val(commaSeparateNumber(deposit_amount_wholesale));
          }
          
          deposit_amount = (parseFloat(total_amount) / 2).toFixed(2) ;          

          $('#_sh_quote_deposit').val(commaSeparateNumber(deposit_amount));
          $('#_sh_quote_total_net').val(commaSeparateNumber(total_amount));

          exchange_rate = $('#_sh_quote_uk_exchange_rate').val();
          uk_total_amount = parseFloat((cut_sub_int + over_sub_int + size_sub_int + seal_sub_int + ship_sub_int + strike_sub_int ) * exchange_rate).toFixed(2);
          uk_deposit_amount = parseFloat(uk_total_amount / 2).toFixed(2) ;
          // $('#_sh_quote_uk_deposit').val(uk_deposit_amount);
          // $('#_sh_quote_uk_total_net').val(uk_total_amount);
          $('#_sh_quote_uk_deposit').val(commaSeparateNumber(uk_deposit_amount));
          $('#_sh_quote_uk_total_net').val(commaSeparateNumber(uk_total_amount));

          if($('body').hasClass('admin-bar')){
            internalQuote();
          }

        }


        if($('.form-region').val() === "US") {
          $('.uk-options').hide();
        } else {

        }

        if ($('#_sh_quote_sel_pattern').val() === '') {
          //on page load, check the pattern, then define some variables (used for edit and preview screens)

        } else {
          var $patName = $('#_sh_quote_sel_pattern').find(':selected').attr('data-id');
          var $patID = $('#_sh_quote_sel_pattern').val();
          var $qID = $('#fp_title').attr('data-qid');

          if ($patID === "custom"){
            $('#frontier_post').addClass('custom-selected');
            $('#_sh_quote_colorway_options').removeAttr('required');
            $('#_sh_quote_colorway').prop('required', true);
            pc = $('#_sh_quote_size_sub_cost_display').val();
            pn = $('#_sh_quote_size_sub_net_display').val();
            pw = $('#_sh_quote_size_sub_wholesale_display').val();
            po = $('#_sh_quote_sel_origin').val();
            $('.custom-sf-inputs input').attr('readonly', false);
            $('.pattern-meta').hide();
            maxlength = $('#_sh_quote_custom_length_in').val();
            handtufted = "no";
            leadtime = $('#_sh_quote_custom_lead').val();

          } else {
            $('.custom-sf-inputs input').attr('readonly', true);
            $('#_sh_quote_colorway').removeAttr('required');
            $('#_sh_quote_colorway_options').prop('required', true);
            $('#frontier_post').removeClass('custom-selected');
            $('.pattern-meta').show();

            $.ajax({
              url:quoteajax.ajaxurl,
              type: 'GET',
              dataType: 'json',
              data: {
                action: 'shi_quoteajax',
                qID: $qID,
                patID: $patID,
                patName: $patName
              },
              success: function(response){
                //set up global variables for the quote calculation
                widthOverride = $('.frontier_post_form').hasClass('width-fee-override') ? 1.15 : 1;
                pc = response.content.cost;
                pn = parseFloat(response.content.net * widthOverride).toFixed(2);
                pw = parseFloat(response.content.wholesale * widthOverride).toFixed(2);
                po = response.content.origin;
                shipOver = response.content.ship_override;
                console.log(shipOver);
                maxlength = response.content.maxlength;
                if ($('.frontier_post_form').hasClass('uk') || $('.frontier_post_form').hasClass('UK')){
                  leadtime = response.content.leadtimeuk != '' ? response.content.leadtimeuk : 2;
                } else {
                  leadtime = response.content.leadtime;
                }
                var newOptions = response.images;
                console.log(newOptions[0].title);
                handtufted = response.content.handtufted;

                var $el = $("#_sh_quote_colorway_options");
                $el.empty(); // remove old options
                if(newOptions[0].title === null){
                    $el.append($("<option></option>")
                       .attr("value", '').text('Select Pattern First'));
                } else {
                  $el.append($("<option></option>")
                     .attr("value", '').text('Please Select'));
                  $.each(newOptions, function(i,imgObject) {
                    if(imgObject.selected === 'selected') {
                      $('.pattern-swatch').append('<img class="'+imgObject.title+' selected" src="'+imgObject.url+'" />');
                      $el.append($("<option></option>")
                         .attr("value", imgObject.title).text(imgObject.title).attr("selected", "selected"));
                    } else {
                      $('.pattern-swatch').append('<img class="'+imgObject.title+'" src="'+imgObject.url+'" />');
                      $el.append($("<option></option>")
                         .attr("value", imgObject.title).text(imgObject.title));
                    }
                  });
                }

                console.log(pw);
                $('.pattern-meta').addClass('pattern-ajax');
                $('#sh_quote_def_origin').val(response.content.origin);
                $('#sh_quote_def_material').val(response.content.material);
                $('#_sh_quote_size_sub_net_display').val(response.content.net);
                $('#_sh_quote_size_sub_cost_display').val(response.content.cost);
                $('#_sh_quote_size_sub_wholesale_display').val(response.content.wholesale);
              }
            })
            .done(function() {
              console.log("success");
            })
            .fail(function() {
              console.log("error");
            })
            .always(function() {
              console.log("complete");
            });

          }
        }

        $('#_sh_quote_sel_pattern').change(function() {
          $('.pattern-swatch').empty().removeClass('show');
          var $patName = $('#_sh_quote_sel_pattern').find(':selected').attr('data-id');
          var $patID = $('#_sh_quote_sel_pattern').val();
          var $qID = $('#fp_title').attr('data-qid');
          console.log(' data id:'+$patName);
          if ($patName === "custom"){
            $('#frontier_post').addClass('custom-selected');
            $("#_sh_quote_colorway_options").empty();
            $("#_sh_quote_def_material").val('');
            $("#_sh_quote_def_origin").val('');
            $("#_sh_quote_colorway_options").empty().append($("<option></option>").attr("value", '').text('Select Pattern First'));
            $('#_sh_quote_colorway_options').removeAttr('required');
            $('#_sh_quote_colorway').prop('required', true);
            $("#_sh_quote_sel_origin").empty()
              .append($("<option></option>").attr("value", 'India').text('India'))
              .append($("<option></option>").attr("value", 'Nepal').text('Nepal'))
              .append($("<option></option>").attr("value", 'Thailand').text('Thailand'))
              .append($("<option></option>").attr("value", 'Uruguay').text('Uruguay'))
              .append($("<option></option>").attr("value", 'Italy').text('Italy'));


            pc = $('#_sh_quote_size_sub_cost_display').val();
            pn = $('#_sh_quote_size_sub_net_display').val();
            pw = $('#_sh_quote_size_sub_wholesale_display').val();
            po = $('#_sh_quote_sel_origin').val();
            maxlengthFt = (isNaN( maxlengthFt = parseInt($('#_sh_quote_custom_length_ft').val(), 10) ) ? 0 : maxlengthFt);
            maxlengthIn = (isNaN( maxlengthIn = parseInt($('#_sh_quote_custom_length_in').val(), 10) ) ? 0 : maxlengthIn);
            maxlength = (maxlengthFt * 12) + maxlengthIn;
            console.log('ml:' +maxlength);
            leadtime = $('#_sh_quote_custom_lead').val();
            $('.custom-sf-inputs input').attr('readonly', false);
            $('.pattern-meta').hide();

          } else {
            $('#_sh_quote_custom_pattern').val('');
            $("#_sh_quote_sel_material").empty().append($("<option></option>").attr("value", '').text('Custom Not Selected'));
            $("#_sh_quote_sel_origin").empty().append($("<option></option>").attr("value", '').text('Custom Not Selected'));
            $('#_sh_quote_colorway_options').prop('required', true);
            $('#_sh_quote_colorway').removeAttr('required');
            $('.custom-sf-inputs input').attr('readonly', true);
            $('#frontier_post').removeClass('custom-selected');
            $('.pattern-meta').show();

            $.ajax({
              url:quoteajax.ajaxurl,
              type: 'GET',
              dataType: 'json',
              data: {
                action: 'shi_quoteajax',
                qID: $qID,
                patID: $patID,
                patName: $patName
              },
              success: function(response){
                pattOptions = response;
                //set up global variables for the quote calculation
                widthOverride = $('.frontier_post_form').hasClass('width-fee-override') ? 1.15 : 1;
                pc = response.content.cost;
                pn = parseFloat(response.content.net * widthOverride).toFixed(2);
                pw = parseFloat(response.content.wholesale * widthOverride).toFixed(2);
                po = response.content.origin;
                shipOver = response.content.shipoverride;
                shipOvert = (shipOver !== '') ? shipOver : 3.00;
                  $('#_sh_quote_ship').attr( 'data-ship', shipOvert)
                  console.log(shipOvert);

                maxlength = response.content.maxlength;
                if ($('.frontier_post_form').hasClass('uk') || $('.frontier_post_form').hasClass('UK')){
                  leadtime = response.content.leadtimeuk != '' ? response.content.leadtimeuk : 2;
                } else {
                  leadtime = response.content.leadtime;
                }
                var newOptions = response.images;
                console.log(newOptions[0].title);
                handtufted = response.content.handtufted;

                var $el = $("#_sh_quote_colorway_options");
                $el.empty(); // remove old options
                if(newOptions[0].title === null){
                    $el.append($("<option></option>")
                       .attr("value", '').text('Select Pattern First'));
                } else {
                  $el.append($("<option></option>")
                     .attr("value", '').text('Please Select'));
                  $.each(newOptions, function(i,imgObject) {
                    if(imgObject.selected === 'selected') {
                      // $('.pattern-swatch').append('<img class="'+imgObject.title+' selected" src="'+imgObject.url+'" />');
                      $el.append($("<option data-cid="+i+"></option>")
                         .attr("value", imgObject.title).text(imgObject.title).attr("selected", "selected"));
                    } else {
                      // $('.pattern-swatch').append('<img class="'+imgObject.title+'" src="'+imgObject.url+'" />');
                      $el.append($("<option data-cid="+i+"></option>")
                         .attr("value", imgObject.title).text(imgObject.title));
                    }
                  });
                }


                console.log(pw);
                $('.pattern-meta').addClass('pattern-ajax');
                $('#sh_quote_def_origin').val(response.content.origin);
                $('#sh_quote_def_material').val(response.content.material);
                $('#_sh_quote_size_sub_net_display').val(pn);
                $('#_sh_quote_size_sub_cost_display').val(response.content.cost);
                $('#_sh_quote_size_sub_wholesale_display').val(pw);
                quoteGen(response);
              }
            })
            .done(function() {
              console.log("success");
            })
            .fail(function() {
              console.log("error");
            })
            .always(function() {
              console.log("complete");
            });
          }


        });

        $('#_sh_quote_colorway_options').change(function(){
          pattID = $('#_sh_quote_colorway_options').find(':selected').data('cid');
          if(pattID > 0){
            $('.pattern-swatch').empty().append('<img class="'+pattOptions.images[pattID].title+' selected" src="'+pattOptions.images[pattID].url+'" />').addClass('show');
          } else {
            $('.pattern-swatch').empty().removeClass('show');
          }
        });

        $('#_sh_quote_sel_origin').change(function(){
          po = $(this).val();
        });


        function validateForm(){
          requiredFields = $('input:required');
          $.each(requiredFields, function(i, el) {
            if(!$(this).val()){
             $(this).parent().addClass('has-error');
           } else {
             $(this).parent().removeClass('has-error');
           }

          });
        }

        $('#user_post_save').click(function(event) {
          console.log('submit clicked');
          validateForm();
        });


        function convfrommeters(frm) {
          var cm2;
          var feet2;
          var inches2;
          var meters2;
          if(isNaN(frm.meters2.value)){
            alert("Please enter numbers only");
            return false;
          }
          inches2 = ((frm.meters2.value) * 39.370078740157477);
          if(inches2===0){
            alert("Please enter valid values into the boxes");
          }

          frm.feet2.value = parseInt(inches2 / 12);
          frm.inches2.value = inches2 % 12;
          return false;
        }


        $('select.quote-revise, input[type=radio]').on('change', function() {

        // alert($(this).data('target'));
           
          input_change = $(this);
          quoteGen(input_change);
        });
        $('.quote-revise').on('input', function() {
          input_change = $(this);
          quoteGen(input_change);
        });

        $('select.form-region').on('change', function() {
          if($(this).val() === 'US'){
            $('.frontier_post_form').addClass('us').removeClass('uk');
            $('.uk-options').hide();
          } else if ( $(this).val() === 'UK') {
            $('.frontier_post_form').addClass('uk').removeClass('us');
            $('.uk-options').show();
          }
          quoteGen(input_change);
        });

        $('.reset').click(function(event) {
          if($(this).attr('data-function') === 'full-reset'){
            $('input:not(".reset-save"), textarea').val("");
            $('select').prop('selectedIndex', 0);
            $('#frontier_post').removeClass('custom-selected');

          } else if ($(this).attr('data-function') === 'partial-reset'){
            $('input:not(".top-info"), textarea:not(".top-info")').val("");
            $('select').prop('selectedIndex', 0);
            $('#frontier_post').removeClass('custom-selected');
          }
        });



      }
    },
    'single_sh_quotes': {
      init: function() {

        function commaSeparateNumber(val){
            while (/(\d+)(\d{3})/.test(val.toString())){
              val = val.toString().replace(/(\d+)(\d{3})/, '$1'+','+'$2');
            }
            return val;
          }


        $('select, input').attr('disabled', 'disabled');
        // JavaScript to be fired on the about us page
        var pc, pn, pw, po, maxlength, leadtime;
        count = 0;

        function internalQuote(){
          console.log('start internal');
          //calculate cost
          size_sub_cost = parseFloat(pc * sq_ft).toFixed(2);
          if(w_total > 12){
            oversize = true;
            over_sub_cost = parseFloat(size_sub_cost * 0.15).toFixed(2);
            $('#_sh_quote_over_sub_cost').val(over_sub_cost);
          } else {
            oversize = false;
            over_sub_cost = 0;
            $('#_sh_quote_over_sub_cost').val(null);
          }
          $('#_sh_quote_size_sub_cost').val(size_sub_cost);

          size_sub_wholesale = parseFloat(pw * sq_ft).toFixed(2);
          if(w_total > 12){
            oversize = true;
            over_sub_wholesale = parseFloat(size_sub_wholesale * 0.15).toFixed(2);
            $('#_sh_quote_over_sub_wholesale').val(over_sub_wholesale);
          } else {
            oversize = false;
            over_sub_wholesale = 0;
            $('#_sh_quote_over_sub_wholesale').val(null);
          }
          $('#_sh_quote_size_sub_wholesale').val(size_sub_wholesale);
          //calculate wholesale
          //calculate percentage discounts
          perc_discount = ($('#_sh_quote_discount_percent').val()) / 100;
          perc_discount_test = (perc_discount === 0 ? NaN : perc_discount);
          perc_disc_net = parseFloat(size_sub * perc_discount_test).toFixed(2);
          perc_disc_net_p = (isNaN(perc_disc_net) ? null : perc_disc_net);
          perc_disc_net_p_disp = ((isNaN(perc_disc_net * (-1))) ? null : (perc_disc_net * (-1)).toFixed(2));
          console.log('perdis'+perc_disc_net_p_disp);
          $('#_sh_quote_discount_percent_display_net').val(perc_disc_net_p_disp);


          perc_disc_wholesale = parseFloat(size_sub_wholesale * perc_discount_test).toFixed(2);
          perc_disc_wholesale_p = (isNaN(perc_disc_wholesale) ? null : perc_disc_wholesale);
          // perc_disc_wholesale_int = (isNaN(perc_disc_wholesale_int = parseFloat(perc_disc_wholesale)) ? 0 : perc_disc_wholesale_int);
          perc_disc_wholesale_p_disp = ((isNaN(perc_disc_wholesale * (-1))) ? null : (perc_disc_wholesale * (-1)).toFixed(2));
          $('#_sh_quote_discount_percent_display_wholesale').val(perc_disc_wholesale_p_disp);


          perc_disc_cost = parseFloat(size_sub_cost * perc_discount_test).toFixed(2);
          perc_disc_cost_p = (isNaN(perc_disc_cost) ? null : perc_disc_cost);
          // perc_disc_cost_int = (isNaN(perc_disc_cost_int = parseFloat(perc_disc_cost)) ? 0 : perc_disc_cost_int);
          perc_disc_cost_p_disp = ((isNaN(perc_disc_cost * (-1))) ? null : (perc_disc_cost * (-1)).toFixed(2));
          $('#_sh_quote_discount_percent_display_cost').val(perc_disc_cost_p_disp);

          //calculate $ amount discount
          discount_amount = parseFloat($('#_sh_quote_discount_amount').val()).toFixed(2);
          discount_amount_p = (isNaN(discount_amount) ? null  : parseFloat(discount_amount).toFixed(2));
          discount_amount_decimal = parseFloat(discount_amount_p).toFixed(2);

          $('.discount_amount_display').val(discount_amount_p);

          //calculate miscellaneous expense

          misc_amount = parseFloat($('#_sh_quote_misc_desc_sub').val());
          // misc_amount_n = Number($('#_sh_quote_misc_desc_sub').val());
          misc_amount_p = (isNaN(misc_amount) ? null  : parseFloat(misc_amount).toFixed(2));
          misc_amount_decimal = parseFloat(misc_amount_p);
          discount_amount_p_disp = (isNaN(discount_amount) ? null  : parseFloat(discount_amount *(-1)).toFixed(2));
          $('.discount_amount_display').val(discount_amount_p_disp);

          //calculate total amount with discounts
          total_amount = parseFloat(cut_sub_int + over_sub_int + size_sub_int + seal_sub_int + ship_sub_int + strike_sub_int - perc_disc_net_p - discount_amount_p + Number(misc_amount_p)).toFixed(2);
          $('#_sh_quote_total_net').val(commaSeparateNumber(total_amount));



          //calculate total wholesale
          size_sub_wholesale_int = (isNaN(size_sub_wholesale_int = parseFloat(size_sub_wholesale)) ? 0 : size_sub_wholesale_int);
          over_sub_wholesale_int = (isNaN(over_sub_wholesale_int = parseFloat(over_sub_wholesale)) ? 0 : over_sub_wholesale_int);
          total_amount_wholesale = parseFloat(cut_sub_int + over_sub_wholesale_int + size_sub_wholesale_int + seal_sub_int + ship_sub_int + strike_sub_int - perc_disc_wholesale_p - discount_amount_p + Number(misc_amount_p)).toFixed(2);
          $('#_sh_quote_total_wholesale').val(commaSeparateNumber(total_amount_wholesale));

          //calculate total cost
          size_sub_cost_int = (isNaN(size_sub_cost_int = parseFloat(size_sub_cost)) ? 0 : size_sub_cost_int);
          over_sub_cost_int = (isNaN(over_sub_cost_int = parseFloat(over_sub_cost)) ? 0 : over_sub_cost_int);
          total_amount_cost = parseFloat(cut_sub_int + over_sub_cost_int + size_sub_cost_int + seal_sub_int + ship_sub_int + strike_sub_int - perc_disc_cost_p - discount_amount_p + Number(misc_amount_p)).toFixed(2);
          $('#_sh_quote_total_cost').val(commaSeparateNumber(total_amount_cost));

          //calculate deposit - use wholesale total for valt users

          // if($('.frontier_post_form').hasClass('valt-user')) {
          //   deposit_amount = parseFloat(total_amount_wholesale / 2).toFixed(2) ;
          // } else {
          //   deposit_amount = parseFloat(total_amount / 2).toFixed(2) ;
          // }
          deposit_amount = parseFloat(total_amount / 2).toFixed(2) ;
          $('#_sh_quote_deposit').val(commaSeparateNumber(deposit_amount));

          //uk totals
          exchange_rate = $('#_sh_quote_uk_exchange_rate').val();
          uk_total_amount = parseFloat((cut_sub_int + over_sub_int + size_sub_int + seal_sub_int + ship_sub_int + strike_sub_int - perc_disc_net_p - discount_amount_p + Number(misc_amount_p)) * Number(exchange_rate)).toFixed(2);
          uk_total_amount_wholesale = parseFloat((cut_sub_int + over_sub_wholesale_int + size_sub_wholesale_int + seal_sub_int + ship_sub_int + strike_sub_int - perc_disc_wholesale_p - discount_amount_p + Number(misc_amount_p)) * exchange_rate).toFixed(2);
          uk_total_amount_cost = parseFloat((cut_sub_int + over_sub_cost_int + size_sub_cost_int + seal_sub_int + ship_sub_int + strike_sub_int - perc_disc_cost_p - discount_amount_p + Number(misc_amount_p)) * exchange_rate).toFixed(2);
          uk_deposit_amount = parseFloat(uk_total_amount / 2).toFixed(2) ;
          $('#_sh_quote_uk_deposit').val(commaSeparateNumber(uk_deposit_amount));
          $('#_sh_quote_uk_total_net').val(commaSeparateNumber(uk_total_amount));
          $('#_sh_quote_uk_total_wholesale').val(commaSeparateNumber(uk_total_amount_wholesale));
          $('#_sh_quote_uk_total_cost').val(commaSeparateNumber(uk_total_amount_cost));

        }

        function quoteGen(input_element){

          if($(input_element).hasClass('input-inches')){
            if(parseInt(input_element.val()) > 11){
              input_element.popover('show');
              input_element.val(11);
            } else {
              input_element.popover('hide');
            }
          }
          if($(input_element).hasClass('input-feet-widthss')){
            if(parseInt(input_element.val()) > 20){
              alert('Please contact SHIIR for lengths greater than 20 feet.');
              input_element.popover('show');
              input_element.val(11);
            } else {
              input_element.popover('hide');
            }
          }
          metricInput = false;
          if ($(input_element).hasClass('metric')) {

            metricInput = true;

            if ($(input_element).is('#width-meters')) {
              w_convert = $(input_element).val();

              inches_per_meter = 39.3700787;
              inches_total = w_convert * inches_per_meter ; /* round to integer */
              wfeet = Math.floor((inches_total / 12)) ; /* assumes division truncates result; if not use floor() */
              winches = Math.round(inches_total % 12) ;/* modulus */
              if( $('.frontier_post_form').hasClass('showroom') && wfeet > 20) {
                alert('Please contact SHIIR for widths greater than 20 feet.');
                input_element.val(0);
                return;
              }

              $('#width-feet').val(wfeet);
              $('#width-inches').val(winches);

            }
            if ($(input_element).is('#length-meters')) {
              l_convert = $(input_element).val();

              inches_per_meter = 39.3700787;
              inches_total = l_convert * inches_per_meter ; /* round to integer */
              lfeet = Math.floor((inches_total / 12)) ; /* assumes division truncates result; if not use floor() */
              linches = Math.round(inches_total % 12) ;/* modulus */

              if( $('.frontier_post_form').hasClass('showroom') && lfeet > 20) {
                alert('Please contact SHIIR for widths greater than 20 feet.');
                input_element.val(0);
                return;
              }

              $('#length-feet').val(lfeet);
              $('#length-inches').val(linches);

            }
          }
          if ($('#frontier_post').hasClass('custom-selected')){
            pc = $('#_sh_quote_size_sub_cost_display').val();
            pn = $('#_sh_quote_size_sub_net_display').val();
            pw = $('#_sh_quote_size_sub_wholesale_display').val();
            po = $('#_sh_quote_sel_origin').val();
            maxlength = $('#_sh_quote_custom_length_in').val();
            leadtime = $('#_sh_quote_custom_lead').val();

          }
          count++;
          // console.log('pn:'+pn+'pw:'+pw+'pc:'+pc);

          //calculate width
          w_ft_int = (isNaN(w_ft_int = parseInt($("#width-feet").val(), 10) * 12) ? 0 : w_ft_int);
          w_in_int = (isNaN(w_in_int = parseInt($("#width-inches").val(), 10)) ? 0 : w_in_int);

          w_total = (w_ft_int + w_in_int)/12;
          w_total_inch = (w_ft_int + w_in_int);
          if( $('.frontier_post_form').hasClass('showroom') && w_total_inch > 240) {
              alert('Please contact SHIIR for widths greater than 20 feet.');
              input_element.val(0);
              return;
          }

          // w_meters = parseFloat(inches_per_meter / w_total_inch);
          // $('#width-meters').val(w_meters);
          console.log('w_total: '+w_total);
          //calculate length
          l_ft_int = (isNaN(l_ft_int = parseInt($("#length-feet").val(), 10) * 12) ? 0 : l_ft_int);
          l_in_int = (isNaN(l_in_int = parseInt($("#length-inches").val(), 10)) ? 0 : l_in_int);

          l_total = (l_ft_int + l_in_int)/12;
          l_total_inch = (l_ft_int + l_in_int);
          if( $('.frontier_post_form').hasClass('showroom') && l_total_inch > 360) {
              alert('Please contact SHIIR for lengths greater than 30 feet.');
              input_element.val(0);
              return;
          }
          // l_meters = parseFloat(inches_per_meter / l_total_inch);
          // $('#length-meters').val(l_meters);


          if($(input_element).hasClass('imperial')) {

            //converting to metric, for UK forms
            if (typeof metricInput === 'undefined' || metricInput === false){
              meters_per_inch = 0.0254;
              w_metric = meters_per_inch * w_total_inch;
              l_metric = meters_per_inch * l_total_inch;
              $('#width-meters').val(w_metric.toFixed(2));
              $('#length-meters').val(l_metric.toFixed(2));

            }

          }

          console.log('l_total:'+l_total);
          //calculate square footage
          sq_ft = Math.ceil(w_total * l_total);
          roundSF = Math.ceil(sq_ft);
          console.log('sf:'+roundSF);
          $('#_sh_quote_size_sf').val(roundSF);
          //calculate net cost
          size_sub = parseFloat(pn * sq_ft).toFixed(2);
          $('#_sh_quote_size_sub_net').val(size_sub);

          //shipping and handling
          if ($('#_sh_quote_ship').val() === 'Yes'){
            shipRate = $('#_sh_quote_sel_pattern').find(':selected').attr('data-pat-ship');
            ship_sub = parseFloat(shipRate * sq_ft).toFixed(2);
            $('#_sh_quote_ship_sub, .sh_quote_ship_sub_display').val(ship_sub);
          } else {
            ship_sub = 0;
            $('#_sh_quote_ship_sub, .sh_quote_ship_sub_display').val(null);
          }


          //check if width is over 12'
          if(w_total > 12){
            oversize = true;
            over_sub = parseFloat(size_sub * 0.15).toFixed(2);
            $('#_sh_quote_over_sub, .sh_quote_over_sub_dup').val(over_sub);
          } else {
            oversize = false;
            over_sub = 0;
            $('#_sh_quote_over_sub, .sh_quote_over_sub_dup').val(null);
          }
          //check for rug pad cut
          if ($('#_sh_quote_pad').val() === 'Yes'){
            cut_sub = parseFloat(2.25 * sq_ft).toFixed(2);
            $('#_sh_quote_cut_sub, .sh_quote_cut_sub_display').val(cut_sub);
          } else {
            cut_sub = 0;
            $('#_sh_quote_cut_sub, .sh_quote_cut_sub_display').val(null);
          }

          //check for fiber seal
          if ($('#_sh_quote_seal').val() === 'Yes'){
            seal_sub = parseFloat(2.25 * sq_ft).toFixed(2);
            $('#_sh_quote_seal_sub, .sh_quote_seal_sub_display').val(seal_sub);
          } else {
            seal_sub = 0;
            $('#_sh_quote_seal_sub, .sh_quote_seal_sub_display').val(null);
          }


          //check for strike-offs
          strike_num = parseInt($('#_sh_quote_strike').val());
          if (strike_num === 0) {
            strike_sub = null;
          } else {
            strike_sub = parseFloat(175 * strike_num).toFixed(2);
          }
          // strike_num_test = (strike_num === 0 ? NaN : strike_num);

          $('#_sh_quote_strike_sub, .sh_quote_strike_sub_display').val(strike_sub);
          //setup strike-off lead time

          if ( strike_num > 0){
            switch (po){
              case 'India':
                $('#_sh_quote_strike_lead').val("6-8 Weeks");
                break;
              case 'Nepal':
                $('#_sh_quote_strike_lead').val("4-6 Weeks");
                break;
              case 'Thailand':
                $('#_sh_quote_strike_lead').val("4-6 Weeks");
                break;
              case 'Uruguay':
                $('#_sh_quote_strike_lead').val("4-6 Weeks");
                break;
              case 'Italy':
                $('#_sh_quote_strike_lead').val("4-6 Weeks");
                break;
            }
          } else {
                $('#_sh_quote_strike_lead').val("");
          }

          //calculate lead time
          //added leadtime override for handtufted rugs
          if(handtufted === "yes"){
            lead_sub = "8-12 weeks + shipping";
            $('#_sh_quote_calc_lead').val(lead_sub);

          } else {
            lead_sub = Math.ceil((l_total_inch / maxlength)*2)/2 + parseFloat(leadtime);
            if(isNaN(lead_sub)) {
              if($('#_sh_quote_sel_pattern').val() === ''){
                $('#_sh_quote_calc_lead').val('');
              } else {
                $('#_sh_quote_calc_lead').val('Enter L/Mo. + Lead Above');
              }
            } else {
              $('#_sh_quote_calc_lead').val(lead_sub+' Months');
            }
          }

          //calculate total
          cut_sub_int = (isNaN(cut_sub_int = parseFloat(cut_sub)) ? 0 : cut_sub_int);
          over_sub_int = (isNaN(over_sub_int = parseFloat(over_sub)) ? 0 : over_sub_int);
          size_sub_int = (isNaN(size_sub_int = parseFloat(size_sub)) ? 0 : size_sub_int);
          seal_sub_int = (isNaN(seal_sub_int = parseFloat(seal_sub)) ? 0 : seal_sub_int);
          ship_sub_int = (isNaN(ship_sub_int = parseFloat(ship_sub)) ? 0 : ship_sub_int);
          strike_sub_int = (isNaN(strike_sub_int = parseFloat(strike_sub)) ? 0 : strike_sub_int);

          total_amount = parseFloat(cut_sub_int + over_sub_int + size_sub_int + seal_sub_int + ship_sub_int + strike_sub_int).toFixed(2);
          deposit_amount = parseFloat(total_amount).toFixed(2) / 2 ;
          $('#_sh_quote_deposit').val(deposit_amount);
          $('#_sh_quote_total_net').val(total_amount);

          exchange_rate = $('#_sh_quote_uk_exchange_rate').val();
          uk_total_amount = parseFloat((cut_sub_int + over_sub_int + size_sub_int + seal_sub_int + ship_sub_int + strike_sub_int ) * exchange_rate).toFixed(2);
          uk_deposit_amount = parseFloat(uk_total_amount / 2).toFixed(2) ;
          $('#_sh_quote_uk_deposit').val(uk_deposit_amount);
          $('#_sh_quote_uk_total_net').val(uk_total_amount);

          if($('body').hasClass('admin-bar')){
            internalQuote();
          }

        }

        if($('.form-region').val() === "US") {
          $('.uk-options').hide();
        } else {

        }

        if ($('#_sh_quote_sel_pattern').val() === '') {
          //on page load, check the pattern, then define some variables (used for edit and preview screens)
          console.log('val is empty');

        } else {
          console.log('continued');
          var $patName = $('#_sh_quote_sel_pattern').find(':selected').attr('data-id');
          var $patID = $('#_sh_quote_sel_pattern').val();
          var $qID = $('#fp_title').attr('data-qid');

          if ($patID === "custom"){
            $('#frontier_post').addClass('custom-selected');
            $('#_sh_quote_colorway_options').removeAttr('required');
            $('#_sh_quote_colorway').prop('required', true);
            pc = $('#_sh_quote_size_sub_cost_display').val();
            pn = $('#_sh_quote_size_sub_net_display').val();
            pw = $('#_sh_quote_size_sub_wholesale_display').val();
            po = $('#_sh_quote_sel_origin').val();
            $('.custom-sf-inputs input').attr('readonly', false);
            $('.pattern-meta').hide();
            maxlength = $('#_sh_quote_custom_length_in').val();
            handtufted = "no";
            leadtime = $('#_sh_quote_custom_lead').val();

          } else {
            $('.custom-sf-inputs input').attr('readonly', true);
            $('#_sh_quote_colorway').removeAttr('required');
            $('#_sh_quote_colorway_options').prop('required', true);
            $('#frontier_post').removeClass('custom-selected');
            $('.pattern-meta').show();

            $.ajax({
              url:quoteajax.ajaxurl,
              type: 'GET',
              dataType: 'json',
              data: {
                action: 'shi_quoteajax',
                qID: $qID,
                patID: $patID,
                patName: $patName
              },
              success: function(response){
                //set up global variables for the quote calculation
                pattOptions = response;
                widthOverride = $('.frontier_post_form').hasClass('width-fee-override') ? 1.15 : 1;
                pc = response.content.cost;
                pn = parseFloat(response.content.net * widthOverride).toFixed(2);
                pw = parseFloat(response.content.wholesale * widthOverride).toFixed(2);

                po = response.content.origin;
                maxlength = response.content.maxlength;
                handtufted = response.content.handtufted;
             
                if ($('.frontier_post_form').hasClass('uk') || $('.frontier_post_form').hasClass('UK')){
                  leadtime = response.content.leadtimeuk != '' ? response.content.leadtimeuk : 2;
                  console.log ('has class');
                } else {
                  leadtime = response.content.leadtime;
                }
                var newOptions = response.images;
                console.log(newOptions[0].title);

                var $el = $("#_sh_quote_colorway_options");
                $el.empty(); // remove old options
                if(newOptions[0].title === null){
                    $el.append($("<option></option>")
                       .attr("value", '').text('Select Pattern First'));
                } else {
                  $el.append($("<option></option>")
                     .attr("value", '').text('Please Select'));
                  $.each(newOptions, function(i,imgObject) {
                    if(imgObject.selected === 'selected') {
                      // $('.pattern-swatch').append('<img class="'+imgObject.title+' selected" src="'+imgObject.url+'" />');
                      $el.append($("<option data-cid="+i+"></option>")
                         .attr("value", imgObject.title).text(imgObject.title).attr("selected", "selected"));
                    } else {
                      // $('.pattern-swatch').append('<img class="'+imgObject.title+' selected" src="'+imgObject.url+'" />');
                      $el.append($("<option data-cid="+i+"></option>")
                         .attr("value", imgObject.title).text(imgObject.title));
                    }
                  });
                }
                pattID = $('#_sh_quote_colorway_options').find(':selected').data('cid');
                if(pattID > 0){
                  $('.pattern-swatch').empty().append('<img class="'+pattOptions.images[pattID].title+' selected" src="'+pattOptions.images[pattID].url+'" />').addClass('show');
                } else {
                  $('.pattern-swatch').empty().removeClass('show');
                }

                console.log(pw);
                $('.pattern-meta').addClass('pattern-ajax');
                $('#sh_quote_def_origin').val(response.content.origin);
                $('#sh_quote_def_material').val(response.content.material);
                //removed these so that values don't get adjusted on page load
                // $('#_sh_quote_size_sub_net_display').val(response.content.net);
                // $('#_sh_quote_size_sub_cost_display').val(response.content.cost);
                // $('#_sh_quote_size_sub_wholesale_display').val(response.content.wholesale);
              }
            })
            .done(function() {
              console.log("success");
            })
            .fail(function() {
              console.log("error");
            })
            .always(function() {
              console.log("complete");
            });

          }
        }

        $('#_sh_quote_sel_pattern').change(function() {
          console.log($(this).attr('data-id'));
          var $patName = $('#_sh_quote_sel_pattern').find(':selected').attr('data-id');
          var $patID = $('#_sh_quote_sel_pattern').val();
          var $qID = $('#fp_title').attr('data-qid');

          if ($patID === "custom"){
            $('#frontier_post').addClass('custom-selected');
            $("#_sh_quote_colorway_options").empty();
            $("#_sh_quote_def_material").val('');
            $("#_sh_quote_def_origin").val('');
            $("#_sh_quote_colorway_options").empty().append($("<option></option>").attr("value", '').text('Select Pattern First'));
            $('#_sh_quote_colorway_options').removeAttr('required');
            $('#_sh_quote_colorway').prop('required', true);

            pc = $('#_sh_quote_size_sub_cost_display').val();
            pn = $('#_sh_quote_size_sub_net_display').val();
            pw = $('#_sh_quote_size_sub_wholesale_display').val();
            po = $('#_sh_quote_sel_origin').val();
            maxlength = $('#_sh_quote_custom_length_in').val();
            leadtime = $('#_sh_quote_custom_lead').val();
            $('.custom-sf-inputs input').attr('readonly', false);
            $('.pattern-meta').hide();

          } else {
            $('#_sh_quote_custom_pattern').val('');
            $("#_sh_quote_sel_material").empty().append($("<option></option>").attr("value", '').text('Custom Not Selected'));
            $("#_sh_quote_sel_origin").empty().append($("<option></option>").attr("value", '').text('Custom Not Selected'));
            $('#_sh_quote_colorway_options').prop('required', true);
            $('#_sh_quote_colorway').removeAttr('required');
            $('.custom-sf-inputs input').attr('readonly', true);
            $('#frontier_post').removeClass('custom-selected');
            $('.pattern-meta').show();

            $.ajax({
              url:quoteajax.ajaxurl,
              type: 'GET',
              dataType: 'json',
              data: {
                action: 'shi_quoteajax',
                qID: $qID,
                patID: $patID,
                patName: $patName
              },
              success: function(response){
                //set up global variables for the quote calculation
                widthOverride = $('.frontier_post_form').hasClass('width-fee-override') ? 1.15 : 1;
                pc = response.content.cost;
                pn = parseFloat(response.content.net * widthOverride).toFixed(2);
                pw = parseFloat(response.content.wholesale * widthOverride).toFixed(2);

                po = response.content.origin;
                maxlength = response.content.maxlength;
                if ($('.frontier_post_form').hasClass('uk') || $('.frontier_post_form').hasClass('UK')){
                  leadtime = response.content.leadtimeuk != '' ? response.content.leadtimeuk : 2;
                } else {
                  leadtime = response.content.leadtime;
                }
                var newOptions = response.images;
                console.log(newOptions[0].title);
                handtufted = response.content.handtufted;

                var $el = $("#_sh_quote_colorway_options");
                $el.empty(); // remove old options
                if(newOptions[0].title === null){
                    $el.append($("<option></option>")
                       .attr("value", '').text('Select Pattern First'));
                } else {
                  $el.append($("<option></option>")
                     .attr("value", '').text('Please Select'));
                  $.each(newOptions, function(i,imgObject) {
                    if(imgObject.selected === 'selected') {
                      // $('.pattern-swatch').append('<img class="'+imgObject.title+' selected" src="'+imgObject.url+'" />');
                      $el.append($("<option></option>")
                         .attr("value", imgObject.title).text(imgObject.title).attr("selected", "selected"));
                    } else {
                      // $('.pattern-swatch').append('<img class="'+imgObject.title+'" src="'+imgObject.url+'" />');
                      $el.append($("<option></option>")
                         .attr("value", imgObject.title).text(imgObject.title));
                    }
                  });
                }


                console.log(pw);
                $('.pattern-meta').addClass('pattern-ajax');
                $('#sh_quote_def_origin').val(response.content.origin);
                $('#sh_quote_def_material').val(response.content.material);
                $('#_sh_quote_size_sub_net_display').val(response.content.net);
                $('#_sh_quote_size_sub_cost_display').val(response.content.cost);
                $('#_sh_quote_size_sub_wholesale_display').val(response.content.wholesale);
                quoteGen(response);
              }
            })
            .done(function() {
              console.log("success");
            })
            .fail(function() {
              console.log("error");
            })
            .always(function() {
              console.log("complete");
            });
          }


        });

        $('#_sh_quote_sel_origin').change(function(){
          po = $(this).val();
        });


        function validateForm(){
          requiredFields = $('input:required');
          $.each(requiredFields, function(i, el) {
            if(!$(this).val()){
             $(this).parent().addClass('has-error');
           } else {
             $(this).parent().removeClass('has-error');
           }

          });
        }

        $('#user_post_save').click(function(event) {
          console.log('submit clicked');
          validateForm();
        });


        function convfrommeters(frm) {
          var cm2;
          var feet2;
          var inches2;
          var meters2;
          if(isNaN(frm.meters2.value)){
            alert("Please enter numbers only");
            return false;
          }
          inches2 = ((frm.meters2.value) * 39.370078740157477);
          if(inches2===0){
            alert("Please enter valid values into the boxes");
          }

          frm.feet2.value = parseInt(inches2 / 12);
          frm.inches2.value = inches2 % 12;
          return false;
        }


        $('select.quote-revise').on('change', function() {
          input_change = $(this);
          quoteGen(input_change);
        });
        $('.quote-revise').on('input', function() {
          input_change = $(this);
          quoteGen(input_change);
        });

        $('select.form-region').on('change', function() {
          if($(this).val() === 'US'){
            $('.uk-options').hide();
          } else if ( $(this).val() === 'UK') {
            $('.uk-options').show();
          }
          quoteGen(input_change);
        });

        $('.reset').click(function(event) {
          if($(this).attr('data-function') === 'full-reset'){
            $('input:not(".reset-save"), textarea').val("");
            $('select').prop('selectedIndex', 0);
            $('#frontier_post').removeClass('custom-selected');

          } else if ($(this).attr('data-function') === 'partial-reset'){
            $('input:not(".top-info"), textarea:not(".top-info")').val("");
            $('select').prop('selectedIndex', 0);
            $('#frontier_post').removeClass('custom-selected');
          }
        });



      }
    },

  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
