(function($){

  // Set zoom step maximum and minimum
  var stepMax = 2;
  var stepMin = 0;
  var totalImages = $('#modal .image-set .image-container a.additional-image-thumb > img').length;
  console.log(totalImages);
  var modalCarouselPage = 0;

  function modalCall() {
    var imageSelect = $('.main-image figure > img').attr('src');
    imageSelect = imageSelect.replace('580', '2000');

    if(totalImages === 1) {
      $('#modal h4').css('display', 'none');
      $('#modal .zoom-additional-images').css('opacity', '0.00');
    }

    $('#modal').fadeToggle('fast');
    $('#draggable').html('<img class="zoom-image" src="' + imageSelect + '"  alt="<?php echo $product; ?>">');
    $('#spinner').delay(300).fadeOut(300, function() {
      $('.zoom-frame').fadeIn(300);
      $('.zoom-image').delay(300).fadeIn(300);
      $('#modal .modal-box .zoom-additional-images').delay(200).fadeIn(300);
    });

    stepPos = 0;
    carouselStep = 0;
    modalCarouselPage = 0;

    if(stepPos === 0) {
      $('.zoom-minus').css('opacity', '0.60');
    }
    if(stepPos !== 0) {
      $('.zoom-minus').css('opacity', '1.00');
    }
    if(stepPos < stepMax) {
      $('.zoom-plus').css('opacity', '1.00');
    }
    if(stepPos === 0) {
      $('.zoom-minus').css('opacity', '0.60');
    }
  }

  $('.zoom-icon').click(function() {
    $('#modal .modal-box .zoom-additional-images .image-container ').css('left', '64');
    modalCall();
    return false;
  });

  $(document).keyup(function(e) {
  if (e.keyCode === 27) {
      $('#modal').fadeOut('fast');
    }
  });

  $('.zoom-close').click(function() {
    $('#modal').fadeOut('fast');
  });

  $('.zoom-plus').click(function() {
    if(stepPos === (stepMax-1)) {
      $('.zoom-plus').css('opacity', '0.60');
    }
    if(stepPos < stepMax) {
      stepPos++;
      if(stepPos !== 0) {
        $('.zoom-minus').css('opacity', '1.00');
      }
      $('.modal-box .zoom-frame img.zoom-image').css('position', 'relative');
      $('#modal .zoom-image').animate({
        width: '+=200',
        height: '+=200',
        top: '-=200',
        left: '-=200'
      }, 200, function() {
        //Animation complete
      });
    } else {
      return false;
    }
  });

  $('.zoom-minus').click(function() {
    if(stepPos > stepMin) {
      stepPos--;
      if(stepPos < stepMax) {
        $('.zoom-plus').css('opacity', '1.00');
      }
      if(stepPos === 0) {
        $('.zoom-minus').css('opacity', '0.60');
      }
      $('.modal-box .zoom-image').css('position', 'absolute');
      $('#modal .zoom-image').animate({
        width: '-=200',
        height: '-=200',
        top: '+=200',
        left: '+=200'
      }, 200, function() {
        if(stepPos === 0) {
          $('#draggable').animate({
            left: '0',
            top: '0'
          }, 300, function() {
            //Animation complete
          });
        }
      });
    } else {
      return false;
    }
  });

  carouselMax = totalImages-7;
  plusImages = totalImages - 7;

  if(totalImages > 7) {
    $('#modal .scroll-right').fadeIn('fast');
  }

  // Carousel scroll
  $('#modal .scroll-left').click(function() {
    if(carouselStep !== 0) {
      carouselStep++;
      modalCarouselPage--;
      if(modalCarouselPage === 0) {
        $('#modal .scroll-left').fadeOut();
      }
      $('#modal .scroll-right').fadeIn();
      $('#modal .image-container').animate({
        left: '+=108'
      }, 200, function() {
        //Animation complete
      });
    }
    return false;
  });

  $('#modal .scroll-right').click(function() {
    if((totalImages - 7) > 0) {
      console.log(modalCarouselPage);
      if(modalCarouselPage <= carouselMax + 2) {
        carouselStep--;
        modalCarouselPage++;
        if(modalCarouselPage > 0) {
          $('#modal .scroll-left').fadeIn();
        }
        if(modalCarouselPage <= carouselMax) {
          $('#modal .scroll-right').fadeIn();
        }
        if(modalCarouselPage === plusImages) {
          $('#modal .scroll-right').fadeOut();
        }
        $('#modal .image-container').animate({
          left: '-=108'
        }, 200, function() {
          //Animation complete
        });
      }
    }
    return false;
  });

})(jQuery);

